import React from "react";
import { FaMicrophone, FaMicrophoneSlash, FaVolumeMute } from "react-icons/fa";
import flag from 'country-code-emoji';

export interface ClientStatus {
    nickname: string,
    country: string,
    input_muted: boolean,
    output_muted: boolean,
    away: boolean
}

export type ClientProps = {
    client: ClientStatus
};

function Client({client}: ClientProps) {
    const icon = (client.output_muted && <FaVolumeMute></FaVolumeMute>) ||
        (client.input_muted && <FaMicrophoneSlash></FaMicrophoneSlash>) ||
        <FaMicrophone></FaMicrophone>;
    
    const flagEmoji = flag(client.country);

    return (
        <span>
            <span className="icon client">
                {icon}
            </span>
            {client.nickname}
            <span className="flag">
             {flagEmoji}
             </span>
        </span>
    )
}

export default Client;