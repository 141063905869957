import { ListGroup, ListGroupItem } from "react-bootstrap";
import Client, { ClientStatus } from "./Client";
import { FaFolder, FaFolderOpen } from 'react-icons/fa';

export interface ChannelStatus {
    id: number,
    name: string,
    clients: ClientStatus[],
    children: ChannelStatus[]
}

export type ChannelProps = {
    channel: ChannelStatus
}

const SPACER_REGEXP = /\[([?lcr]?)[sS]pacer[\d]*\](.*)/

const SPACER_ALIGNMENT_MAP = {
    'l': 'float-start',
    'c': 'float-center',
    'r': 'float-end',
}
type AlignmentKey = keyof typeof SPACER_ALIGNMENT_MAP;

const SPACER_CLASSES_MAP = {
    '...': 'spacer-dotted',
    '___': 'spacer-straight',
    '---': 'spacer-dashed',
    '-.-': 'spacer-dashed',
    '-..': 'spacer-dashed'
};
type SpacerKey = keyof typeof SPACER_CLASSES_MAP;

function Channel({ channel }: ChannelProps) {
    const hasClients = channel.clients.length > 0;
    const folderIcon = hasClients ? (<FaFolderOpen></FaFolderOpen>) : (<FaFolder></FaFolder>);
    const spacerGroups = channel.name.match(SPACER_REGEXP);

    const alignmentKey = spacerGroups && spacerGroups[1];
    const alignment = SPACER_ALIGNMENT_MAP[alignmentKey as AlignmentKey];

    const spacerKey = spacerGroups && spacerGroups[2];
    const spacer = SPACER_CLASSES_MAP[spacerKey as SpacerKey];

    return (
        <>
            <div>
                {!spacerGroups &&
                    <span className="icon channel">
                        {folderIcon}
                    </span>
                }
                {spacer ? <div className={spacer} /> :
                    <span className={alignment}>
                        {spacerGroups ? spacerGroups[2] : channel.name}
                    </span>
                }
            </div>
            <ListGroup variant="flush">
                {
                    channel.children.map((child) => {
                        return (
                            <ListGroupItem key={child.id}>
                                <Channel channel={child} />
                            </ListGroupItem>
                        )
                    })
                }
                {
                    channel.clients.map((client) => {
                        return (
                            <ListGroupItem key={client.nickname}>
                                <Client client={client} />
                            </ListGroupItem>
                        )
                    })
                }
            </ListGroup>
        </>
    )
}

export default Channel;