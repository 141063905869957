import React, { useEffect, useState } from 'react';
import './App.css';
import { Container, Row, Spinner } from 'react-bootstrap';
import Status, { ServerInfo } from './Status';

export type Response = {
  success: boolean,
  error: string | undefined,
  server_info: ServerInfo
}

function App() {
  const [response, setResponse] = useState<Response | undefined>(undefined);

  useEffect(() => {
    fetch("/status")
      .then(res => res.json())
      .then((result) => {
        setResponse(result);
      })
  }, []);

  return (
    <Container>
      <Row>
        {!response ?
          <div className="loading">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : <Status serverInfo={response?.server_info}></Status>
        }
      </Row>
    </Container>
  );
}

export default App;
