import React from "react"
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap"
import Channel, { ChannelStatus } from "./Channel"

export interface ServerInfo {
    name: string,
    version: string,
    platform: string,
    channels: ChannelStatus[]
}

type StatusProps = {
    serverInfo: ServerInfo
}

function Status({ serverInfo }: StatusProps) {
    document.title = serverInfo.name;

    return <>
        <Card title={serverInfo.name}>
            <Card.Body>
                <Card.Title>
                    {serverInfo.name}
                    <Button className="float-end" variant="outline-success" size="sm" href="ts3server://ts.diff.cc">
                        Connect
                    </Button>
                </Card.Title>
                <Card.Subtitle className="text-muted">
                    {serverInfo.version} on {serverInfo.platform}
                </Card.Subtitle>
            </Card.Body>
        </Card>
        <ListGroup variant="flush">
            {
                serverInfo.channels.map((channel) => {
                    return (
                        <ListGroupItem key={channel.id}>
                            <Channel channel={channel} />
                        </ListGroupItem>)
                })
            }
        </ListGroup>
    </>
}

export default Status